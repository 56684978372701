.pet_card {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
}
.petcard_img{
    height: 12vh;
    border-radius: 10px;
    border: 1px solid white;
    margin-top: 5vh;
}
.glow_petcard {
    background-color: #763AD7;
    height: 40vh;
    position: fixed;
    width: 100vw;
    box-shadow: 0px 20px 20px 20px #763AD7;
    z-index: -1;
    top: 0;
}
.header_petcard_background {
    position: absolute;
    width: 130%;
    height: 50vh;
    background: radial-gradient(circle at center, #FFFFFF, rgba(255, 255, 255, 0) 66%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 0%;
    overflow: visible;
    z-index: -1;
}
.pet_details {
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 600;
    align-items: center;
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.pet_details_text {
    display: flex;
    align-items: center;
    font-weight: 400;
}
