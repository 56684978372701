
$border-width: 3px;
$top-margin:30vh;
$top_margin_observe:0vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);
.outer-container_pump{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top_margin_observe);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top_margin_observe;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_pump {
    width: 100%;
    height: calc(100% - 6px);
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
    margin-bottom: -6px;
    overflow-y: scroll;
    padding-bottom: 200px;
}

.background_pump{
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    height: 100%;
    padding-top: 10px;
    width: 100%;
}
.pump_info_container{
    width: 100%;
}

#ton-connect-button {
    z-index: 9999;
    position: absolute;
    button {
      background: transparent !important;
      color: transparent !important;
    }
  
    div {
      background: transparent !important;
      color: transparent !important;
    }
    
  svg {
    display: none;
  }
  
  }

  .go3974562317 {
    background-color: #0098EA;
    color: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
    padding: 8px 16px 8px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 60px !important;
    width: 90vw !important;
}

.button_connect {
  width: 100%;
  padding: 45px;
  border: none;
  color: white;
  background: linear-gradient(135deg, #1380bc 0%, black 33%, black 66%, #1380bc 100%);
  position: relative;
}
.pump_title{
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  position: absolute;
  bottom: 10px;
}
.pump_top_container{
  position: relative;
}
.lime{
  color: lime;
}


.pampu-block {
  background: linear-gradient(135deg, #1380bc 0%, black 33%, black 66%, #1380bc 100%);
  border-radius: 10px;
  padding: 20px;
  color: white;
  text-align: center;


  .block-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .usdt-you, .usdt-potential {
      text-align: center;
      padding: 5px 20px;
      border-radius: 20px;
      position: relative;

      .usdt-amount 
      {
        font-size: 36px;
        font-weight: bold;
        width: 100%;
        min-width: 120px;
      }
      .usdt-label 
      {
        font-size: 14px;
        color: #b0b0b0;
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        img{
          height: 12px;
        }
      }
    }
    .usdt-you{
      background: linear-gradient(18deg, #1380bc, #093b56);    
    }
    .usdt-potential{
      background-color: white;
      background-image: url('./1.png');  
      background-size: cover;
      background-position: center;
    }
    .arrow-pump 
    {
      font-size: 24px;
      color: #00b300;
    }

    .usdt-potential 
    {
      .potential-amount 
      {
        font-size: 36px;
        font-weight: bold;
        color: #00b300;
      }
      .potential-label 
      {
        font-size: 14px;
        color: #00b300;

        i {
          font-size: 12px;
        }
      }
    }
  }

  .ready-section {
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    .percent_container{

      display: flex;
      flex-direction: row;
      align-items: center;

      .progress_bar_pump {
        width: 60%;
        height: 20px;
        background: rgb(68, 46, 1);
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        color: white;
      }
  
      .status-text {
        font-size: 20px;
        margin-bottom: 5px;
        width: 40%;
        font-weight: 600;
      }
    }
    

    .ready-text {
      font-size: 20px;
      font-weight: 600;
      color: white;

      span {
        color: #00ff00;
      }
    }
  }
}
.percent_ready{
  color: white;
    z-index: 3;
    position: absolute;
    width: 100%;
    font-weight: 800;
}
.input-value{
  background: none;
  border: none;
  text-align: center;
  outline: none;
  color: white;
}
.finger {
  position: absolute;
  max-width: 60px;
  top: 60%;
  left: 60%;
  animation: tap 4s;
  opacity: 0;
}

@keyframes tap {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  25% {
    transform: translateY(-20px) translateX(-20px);
    opacity: 1;
  }
  50% {
    transform: translateY(0px) translateX(0);
    opacity: 1;
  }
  75% {
    transform: translateY(-20px) translateX(-20px);
    opacity: 1;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 0;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  padding: 20px;
  border-radius: 10px;
  max-width: 90vw;
  text-align: center;
  color: white;
}

.popup-warning {
  font-weight: bold;
  color: yellow;
}

.popup-highlight {
  color: lime;
  font-weight: bold;
}

.popup-button {
  background-color: #0072ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 16px;
}
.promo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(45deg, #00e9fb, #0b1b6f);
  border-radius: 15px;
  padding: 10px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  margin-bottom: 20px;
}

.promo-text {
  margin: 0;
  font-size: 20px;
}

.promo-button {
  display: flex;
  align-items: center;
  background-color: white;
  color: #007bff;
  padding: 10px 5px;
  border-radius: 24px;
  font-size: 10px;
  font-weight: 600;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  min-width: 100px;
  justify-content: space-around;
}

.promo-button .arrow-icon {
  margin-left: 8px;
  width: 18px;
}


.container_letsgo{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 10vh;


  .icon1_small {
      width: 45px;
      position: absolute;
      right: -5%;
  }

  .icon2{
    position: absolute;
    height: 200px;
    right: 0;
    top: -100px;
  }

  .button_letsgo {
    font-size: 25px;
    background: linear-gradient(180deg, #9e16edba, #e730cdd6);
    padding: 20px;
    border: 2px solid #d305c6;
    border-radius: 15px;
    font-weight: 700;
    color: white;
    box-shadow: 0px 0px 60px 40px rgba(251, 79, 255, 0.3);
    z-index: 1;
    width: 70%;
    text-align: center;
    animation: pulse 2s infinite; 
}

@keyframes pulse {
    0% {
        transform: scale(1); 
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1); 
    }
}



}
.progress_bar_pump_honey{
  width: 100%;
  height: 25px;
  background: rgb(68, 46, 1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ready_text{
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  font-size: 23px;
  font-weight: 700;
}
.promo-container-buy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #bdff01, #64b510);
  border-radius: 15px;
  padding: 10px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  box-shadow: 0px 0px 20px 10px rgb(122 255 93 / 64%);

}

@keyframes pulse2 {
  0% {
      transform: scale(1); 
  }
  50% {
      transform: scale(1.02);
  }
  100% {
      transform: scale(1); 
  }
}

.icon2_small {
  width: 55px;
  position: absolute;
  right: 15%;
}
.icon1_small {
  width: 45px;
  position: absolute;
  right: -5%;
}

.promo-button_buy {
  display: flex;
  align-items: center;
  background-color: white;
  color: #B51C7D;
  padding: 10px 10px;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  min-width: 90px;
  position: relative;
  font-weight: 800;
}
.height100{
    height: 100vh;
    position: relative;

}