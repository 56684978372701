.divider {
    height: 66%;
    width: 2px;
    margin: 0 6px;
    background: linear-gradient(#00D1FF, #8165FF, #FA00FF);
}
.menu {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #282B30;
    border-radius: 10px;
    padding: 5px 12px;
}
.menu-button img {
    width: 33px;
    height: 33px;
    z-index: 1;
}
.menu-button {
    border: none;
    padding: 0;
    width: 25%;
    height: auto;
    max-width: 60px;
    background: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    outline: none;
    font-weight: bold;
  }
  .glow_games{
    box-shadow: 3px 3px 20px 10px rgba(251, 79, 255, 0.3), -3px -3px 20px 10px rgba(37, 177, 255, 0.3), inset 3px -3px 20px rgba(251, 79, 255, 0.3), inset -3px 3px 20px rgba(37, 177, 255, 0.3);
  }