

.App {
  text-align: center;
}

.link {
  text-decoration: 'none';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#root{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  touch-action: manipulation;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

}

body {
  background: rgb(29, 29, 29);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #eee;
  overflow: hidden;

  font-family: Arial, sans-serif;
}

.background-glow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center bottom, rgba(225, 0, 255, 0.8) 0%, rgba(227, 13, 255, 0) 50%);
  pointer-events: none;
  z-index: -1;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.coin-icon {
  background-image: url('images/coin.png');
  display: inline;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: -0.1em;
  width: 1em;
  height: auto;
  vertical-align: middle;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  max-width: 480px;
  margin: auto;
  padding: 5px 20px;
}

.header, .main-content, .footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;;
  align-items: center;
  z-index: 0;
}

.main-content {
  flex-grow: 1;
}

.player-team-info {
  display: flex;
    width: 75%;
    background: linear-gradient(90deg, rgba(0, 194, 255, 0.67) 0%, rgba(255, 0, 230, 0.38) 100%);
    border-radius: 10px;
    padding: 4px;
    text-decoration: none;
    color: white;
    min-height: 64px;
    justify-content: center;
    align-items: center;
    background-image: url("/src/pages/111.png");

}

.player-team-join {
  display: flex;
  text-align: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.player-team-info > div {
  display: flex;
  flex-grow: 1;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10%;
}

.player-team-logo {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.player-team-name {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 8px;
}

.player-team-stats {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}

.player-team-coins {
  font-size: 12px;
  font-weight: bold;
}

.player-team-league {
  text-align: right;
  font-size: 12px;
}

.play-zone {
  position: relative;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pet {
  display: none;
  position: absolute;
  aspect-ratio : 1 / 1;
  width: 20%;
  max-width: 92px;
  cursor: pointer;
  transform: translate(-50%, -50%);
  animation: scaleUp 0.25s ease-in-out forwards;
}

.pet img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.special {
  display: none;
  position: absolute;
  aspect-ratio : 1 / 1;
  width: 20%;
  max-width: 92px;
  left: 50%;
  top: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  animation: scaleUp 0.25s ease-in-out forwards;
}

.special img {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: shake 0.25s infinite alternate;
}

@keyframes shake {
  from {
      transform: scale(1);
  }
  to {
      transform: scale(1.1);
  }
}

@keyframes scaleUp {
  0% {
      transform: translate(-50%, -50%) scale(0);
  }
  90% {
      transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
      transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes scaleDown {
  0% {
      transform: translate(-50%, -50%) scale(1);
  }
  100% {
      transform: translate(-50%, -50%) scale(0);
  }
}

.income {
  position: absolute;
  font-size: 36px;
  z-index: 1;
  font-weight: bold;
  color: rgb(255, 255, 255);
  opacity: 1;
  transition: opacity 0.5s, top 0.75s;
  pointer-events: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  text-align: center;
  transform: translate(-50%, -50%) 
}


.gameplay-coin {
  height: auto;
  z-index: 0;
  /* perspective: 1000px; */

}

.gameplay-coin-glow {
  position: absolute;
  width: 100vw;
  height: 50vh;
  background: radial-gradient(rgb(109 109 109), rgb(255 255 255 / 0%) 66%);
  z-index: 0;
  perspective: 1000px;
}

.stats {
  display: flex;
  align-items: center;
  z-index: 1000;
}



.button-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 10px;
  font-weight: 700;
}



.button-long {
  width: 100%;
  min-height: 48px;
  padding: 12px 8px;
  margin-bottom: 4px;
  margin-top: 4px;
  background-color: orange;
  color: white;
  font-weight: normal;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-orange {
  background: rgba(255, 149, 51, 1);
  box-shadow: 0px 0px 8px rgba(255, 149, 51, 1);
}

.button-purple {
  background: linear-gradient(90deg, #FB4FFF 0%, #25B1FF 100%);
  box-shadow: -2px 0px 10px 0px #FB4FFF, 2px 0px 10px #25B1FF;
}

.tab {
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 12px;
  background: radial-gradient(circle at center bottom, rgb(118, 58, 215), rgb(23, 23, 23) 50%);
}

.tab-container {
  width: 100%;
  height: 100%;
  transition: bottom 0.25s ease;
  padding: 20px;
  border-radius: 0px 0px 0px 0px;
  box-sizing: border-box;
  overflow-y: auto;
}

.tab-button-arrow {
  width: 32px;
  height: 32px;
  margin-left: auto;
  background-color: transparent;
  color: white;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: transform 0.1s ease;
}

.tab-button-arrow:active {
  transform: scale(0.75);
}

.tab-header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
}

.tab-header-background {
  position: absolute;
  width: 420px;
  height: 420px;
  background: radial-gradient(circle at center, rgba(118, 58, 215, 0.75), rgba(118, 58, 215, 0) 66%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  overflow: visible;
  z-index: -1;
}

.tab-img {
  display: block;
  width: 50%;
  max-width: 110px;
  height: auto;
  margin: 0 auto;
  margin-top: 16px;
}

.tab-title {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 8px;
}

.tab-subtitle {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
}





/* modal */





/* Tasks modal */

.task-header-background {
  background: radial-gradient(circle at center, rgba(191, 58, 215, 0.75), rgba(202, 58, 215, 0) 66%);
}

.task-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.task-section-title {
  font-weight: bold;
  font-size: 16px;
}

.task-blocks-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  gap: 16px;
}

.task-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 280px;
}

.task {
  display: flex;
  align-items: center;
  width: 100%;
}

.task-icon {
  flex: 0 0 auto;
  width: 36px;
  height: 36px;
  font-size: 24px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(251, 79, 255, 0.5) 0%, rgba(37, 177, 255, 0.5) 100%);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-icon > img {
  width: 66%;
  aspect-ratio: 1 / 1;
}

.task-text {
  flex: 1;
}

.task-title {
  font-size: 11px;
  font-weight: normal;
  margin-bottom: 8px;
}

.task-prize {
  font-size: 12px;
  font-weight: bold;
}

.task-status {
  width: 32px;
  height: 32px;
  font-size: 16px;
}

/* Frens modal */

.shop-header-background {
  background: radial-gradient(circle at center, rgba(191, 58, 215, 0.75), rgba(118, 58, 215, 0) 66%);
}

.frens-mainblock-img {
  display: block;
  width: 32px;
  max-width: 110px;
  height: auto;
}

.frens-coins-earned {
  font-weight: bold;
}

.frens-block-title {
  font-weight: bold;
  margin-bottom: 4px;
}

.frens-button-info {
  background: linear-gradient(90deg, #FB4FFF 0%, #25B1FF 100%);
  box-shadow: 0px 0px 16px rgb(48, 165, 255);
}

/* Shop modal */

.shop-header-background {
  background: radial-gradient(circle at center, rgba(191, 58, 215, 0.75), rgba(202, 58, 215, 0) 66%);
}

.shop-balance {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 24px;
  margin-top: 24px;
}

.shop-upgrade-name {
  margin-bottom: 4px;
}

.shop-upgrade-img-case {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  height: 69px;
  border-radius: 8px;
}

.shop-special-section {
  display: flex;
  flex-direction: column;
}

.shop-spcial-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  gap: 12px;
}

.shop-spcial-container > div {
  flex: 1 0 calc(50% - 6px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  text-align: left;
  gap: 8px;
  z-index: 5;
}

.shop-special-name {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
}

.shop-special-name{
  font-size: 13px;
  font-weight: bold;
}

.shop-special-image {
  width: 56px;
  height: 56px;
}

.shop-upgrade-img {
  width: 24px;
  height: 24px;
}

.shop-upgrade-divider {
  height: 10px;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  margin-left: 8px;
  margin-right: 8px;
}

.shop-upgrade-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-weight: bold;
}

.shop-upgrade-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.shop-upgrade-price {
  font-weight: bold;
}

/* Team tab */

.team-tab {
  background: radial-gradient(circle at top, rgba(255, 255, 255, 1), rgba(86, 103, 255, 1), rgb(0, 0, 0) 50%);
}

.team-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
  gap: 32px;
}

.team-title {
  font-size: 20px;
  font-weight: bold;
}

.team-logo {
  width: 128px;
  border-radius: 8px;
  height: 128px;
  object-fit: cover;
}

.team-details {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.team-details-case {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.team-details-case > h2 {
  font-size: 16px;
  font-weight: bold;
}

.team-details-case > p {
  font-size: 12px;
  font-weight: normal;
}

/* Team Explorer tab */

.teamexplorer-header-background {
  width: 640px;
  height: 640px;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 1), rgba(118, 58, 215, 1), rgba(118, 58, 215, 0) 66%);
  transform: translate(-50%, -60%);
  left: 50%;
  top: 50%;
}

.teamexplorer-search-input::placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.teamexplorer-search-input {
  text-align: center;
}

.teamexplorer-list {
  overflow-y: auto;
  max-height: 360px;
}

.teamexplorer-item {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  border-radius: 8px;
}

.teamexplorer-logo {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 10%;
  object-fit: cover;
}

.teamexplorer-details {
  flex: 1;
}

.teamexplorer-name {
  font-weight: bold;
  margin-bottom: 4px;
}

.teamexplorer-league {
  color: lightgray;
}

/* Team modal */

.nikiboom-gamelist {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.nikiboom-gamelist > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  gap: 8px;
  width: 100%;
}

.nikiboom-game-info {
  display: flex;
  flex-direction: row;
  gap: 8px;
}



/* Special background */

.effect-background {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.special-background {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: radial-gradient(circle at center bottom, rgb(242, 137, 10), rgb(23, 23, 23) 75%);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: 15px;
}

.special-background span {
  position: relative;
  bottom: 0;
  width: 50px;
  height: 50px;
  background-image: url('images/coin.png');
  background-size: cover;
  animation: 10s coinrise linear infinite;
  animation-duration: calc(50s / var(--i));
}

@keyframes coinrise {
  0% {
    transform: translateY(110vh) scale(0.1);
  }
  100% {
    transform: translateY(-10vh) scale(1);
  }
}