$pump-gradient: linear-gradient(90deg, #FB4FFF, #25B1FF);
$border-radius: 30px;
$button-bg: #007bff;
$button-hover-bg: #0056b3;
$mint-button-color: #00aaff;
$mint-button-hover-color: #0088cc;

.outer-container_pump {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 0vh);
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  overflow: hidden;
  box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3),
    -3px -3px 20px rgba(37, 177, 255, 0.3),
    3px -3px 20px rgba(251, 79, 255, 0.3),
    -3px 3px 20px rgba(37, 177, 255, 0.3);
  margin-top: 0vh;

  &::before {
    content: '';
    position: absolute;
    top: -3px;
    left: 0;
    right: 0;
    bottom: 3px;
    background: $pump-gradient;
    z-index: -1;
    border-radius: inherit;
  }
}

.container_glow_pump {
  width: 100%;
  height: calc(100% - 6px);
  padding: 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #111111;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
  margin-bottom: -6px;
  overflow-y: scroll;
  padding-bottom: 200px;
  overflow-x: hidden;
}

.mint-info {
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  background: linear-gradient(135deg, #750ca7, #3961bd), url("./bacl.png");;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  .nft-count {
    margin-bottom: 15px;
    text-align: center;

    h3 {
      color: #ffffff;
      font-size: 2rem;
      font-weight: bold;
    }

    .progress-bar-container {
      background: #442e01;
      overflow: hidden;
      height: 25px;
      margin: 10px 0;
      justify-content: flex-end;
      border-radius: 15px;
      display: flex;
      align-items: center;

      .progress-bar {
        background: #d06800;
        height: 100%;
        transition: width 0.3s ease-in-out;
        border-radius: 15px;
      }
    }

    span {
      color: #ffffff;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .rarity-section {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    gap: 10px;

    .rarity-card {
      text-align: center;
      color: #ffffff;
      border-radius: 15px;

      img {
        margin-bottom: 10px;
        height: 90px;
      }

      .rarity-info {
        p {
          font-weight: bold;
          font-size: 18px;
          border-radius: 15px;
          padding: 5px;
          text-shadow: 
          -1px -1px 0 #000,  
          1px -1px 0 #000,  
          -1px 1px 0 #000,  
          1px 1px 0 #000;  
        }
        span {
          font-size: 0.8rem;
        }
      }
    }
  }

  .extra-info {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    gap: 10px;
    align-items: center;


    span{
      background: linear-gradient(45deg, #3265c4, rgba(55, 41, 121, 0.568627451));
      border-radius: 15px;
      color: #fde400;
      font-size: 15px;
      padding: 5px;
      height: fit-content;
      text-align: center;
    }

  }

 
}
.mint-button {
  background: linear-gradient(90deg, #00aaff, #007bff);
  color: #fff;
  padding: 10px 40px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.2s ease;
  width: 100%;
  border: 1px solid #fcbd00;
  margin-top: 20px;
  box-shadow: rgb(253, 228, 0) 0px 0px 20px 5px;
}
.info_counter{

  position: absolute;
  text-align: center;
  width: 90%;
  font-size: 18px;
  color: #adadad;
  font-weight: 800;
}
.left_conatiner{
    display: flex;
    flex-direction: column;
    width: 100%;

    .sp1{
      width: 90%;
      margin: auto;
      font-size: 2.5rem !important;
    }
    .sp2{
      width: 75%;
      margin: auto;
      text-align: right;
    }
}
.countdown_nft {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}