$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer-container_boost {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top-margin;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_shop {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  padding: 3vw 3vw 0 3vw;
  margin: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
}

.coins_container_shop{
    text-align: center;
    margin-bottom: 3%;
}

.coins_text{
    text-align: center;
    margin: 8px 0;
}


.navigationTab {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: #282B30;
  width: 100%;

  .navButton {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    justify-content: center;
    width: 33%;
    padding: 15px 0;
    cursor: pointer;
    transition: background 0.3s ease;
    gap: 5px;
    height: 100%;


    img {
      width: 25px;
      height: 25px;
    }
  }

  .selected {
    background: linear-gradient(165deg, rgba(158, 0, 255, 0.4392156863) 39%, rgba(9, 60, 193, 0.3882352941));
    border-right: 1px solid rgb(250, 0, 255);
    border-left: 1px solid rgba(51, 0, 255, 0.51);
    border-radius: 15px;
  }
}
.coin_icon_shop {
  width: 20px;
  height: 20px;
}

.coin_balance_shop {
  width: 45px;
  height: 45px;
}
.coin_icon_food {
  width: 25px;
  height: 25px;
}

.petCard {
  display: flex;
  align-items: center;
  background: #282B30;
  border-radius: 10px;
  padding: 10px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgb(250 0 255 / 53%);
  border-left: 1px solid hsl(252deg 100% 50% / 37%);
  
    .iconWrapper {
      flex-shrink: 0;
      img.petIcon {
        width: 50px; 
        margin-right: 5px;
      }
    }
  
    .infoWrapper {
      flex-grow: 1;
  
      .petName {
        font-size: 10px;
        margin: 0;
        font-weight: bold;
        text-align: justify;
      }
  
      .petProfit {
          font-size: 12px;
          color: #3EA400;
          margin: 5px 0px;
          font-weight: 500;
      
        
      }
      .profitValue {
        color: #ffd700; 
        margin-top: 3px;
        font-size: 12px;

 
      }
      .levelInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
  
        .levelLabel {
            font-size: 12px;
            color: #FEFEFE;
        }
  
       .coins {
          font-size: 12px;
          color: #FEFEFE;
          font-weight: 500;
      }
      }
    }
  
    
  }
  .shop_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
    width: 100%;
    overflow-y: scroll;
    position: relative;
  }
  

  .foodCard {
    display: flex;
    align-items: center;
    background: #282B30;
    border-radius: 10px;
    padding: 10px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgb(250 0 255 / 53%);
    border-left: 1px solid hsl(252deg 100% 50% / 37%);
    
    .coming_soon{
      font-size: 10px;
    }


    svg{
      height: 15px;
    }

      .iconWrapper {
        flex-shrink: 0;
        img.petIcon {
          width: 45px;
          margin-right: 10px;
          background: linear-gradient(90deg, #FB4FFF, #25B1FF);
          padding: 5px;
          border-radius: 10px;
        }
      }
      .foodName {
        font-size: 15px;
        margin: 0;
        font-weight: 300;
        margin-bottom: 5px;
      }
      .infoWrapper {
        flex-grow: 1;
    
        .profitValue {
          color: #ffd700; 
          margin-top: 3px;
          font-size: 12px;
          
   
        }
        
        .levelInfo {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
    
          .levelLabel {
              font-size: 12px;
              color: #FEFEFE;
          }
    
          .coins {
             
            font-size: 12px;
          }
        }
      }
    
      
}

.modal-content_shop {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
  flex-direction: column;
  justify-content: center;
  padding: 5vw;
}
.text-modal{
  font-size: 20px;
  text-align: center;
  margin: 3px 0;
}
.modal-image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  text-align: center;
  font-size: 4rem;
  margin-bottom: 20px;
}

.coin_icon_shop_modal {
  width: 35px;
  height: 35px;
}

.coins_modal_shop {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-image_shop {
  margin-bottom: 40px;
  max-width: 30vw;
}
.text-modal-level{
  text-align: center;
  margin: 10px 0;
}
.text-modal-profit {
  font-weight: 400;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coin_icon_shop_modal_total {
  width: 55px;
  height: 55px;
}
.button_upgrade {
  width: 100%;
  min-height: 48px;
  padding: 20px 8px;
  margin-top: 5px;
  background-color: #FF9533;
  color: white;
  font-size: 1.5rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 800;
}
.disabled{
  pointer-events: none;
}
.coming_soon_text {
  color: #FFA800;
  font-size: 9px;
}
.craft_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.coming_pets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}
.coming_pet {
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, rgba(0, 194, 255, 0.67), rgba(255, 0, 229, 0.38));
  border-radius: 10px;
  padding: 15px 10px;
  width: 48%;
  position: relative;
}
.coming_pet_img{
  height: 5vh;
}
.coming_title{
  width: 100%;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 5px;
}
.text_coming_mega {
  color: #FFA800;
  font-size: 10vw;
  font-weight: 700;
  width: 100%;
  text-align: center;
}
.mega_market_container{
  position: relative;
  width: 100%;
  height: 100%;
}
.background_mega{
  background-image: url("./back.png");
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
}

.background_mega_coming {
  background: rgba(0, 0, 0, 0.7294117647);
  width: 100%;
  height: 100%;
  position: static;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}

.img_coming_shop {
  max-height: 40vh;
  width: 90vw;
}
.pointer_shop{
  width: 7px;
}
.offer_shop {
  z-index: 2;
  position: absolute;
  width: 26%;
  top: -15px;
  right: -5px;
}