$border-width: 3px;
$top-margin: 30vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(90deg, #fb4fff, #25b1ff);
@mixin small-mobile {
  @media screen and (min-width: 320px) and (max-width: 420px) {
    @content;
  }
}
.outer_container_map {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 70vh;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  // overflow: hidden;
  box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3),
    -3px -3px 20px rgba(37, 177, 255, 0.3),
    3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
  position: absolute;
  bottom: 0;
  z-index: 1000;
}
.outer_container_map::before {
  content: "";
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  bottom: 3px;
  background: linear-gradient(90deg, #fb4fff, #25b1ff);
  z-index: -1;
  border-radius: inherit;
}

.container_glow_map {
  width: 100%;
  height: calc(100% - 6px);
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #1f1f1f, #343434);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
}

.map {
  height: 100%;
  width: 100%;
  position: relative;
}

.island1 {
  width: 30%;
  height: 35%;
  position: absolute;
  left: 0;
  top: 20%;
  z-index: 1;
}

.island2 {
  position: absolute;
  width: 40%;
  height: 30%;
  bottom: 0;
  z-index: 1;
  left: 28%;
}

.talesTelegramia_map {
  font-family: "Kaushan Script";
  -webkit-text-stroke: 0.5px white;
  font-size: 10vw;
  color: #0088cc;
  margin-top: 35px;
  position: absolute;
  z-index: 10;
  top: 0;
}

.back-island {
  position: absolute;
  max-width: 60px;
  left: 14px;
  bottom: 14px;
  border: none;
  z-index: 1000000;
  border: 3px solid rgb(226, 43, 165);
  border-radius: 8px;
}
.back-island_position {
  position: relative;
  left: 0px;
  top: 15px;
  max-width: 40px;
}

.back-button {
  border: none;
  background-color: transparent;
}
.task-1 {
  position: absolute;
  z-index: 10000;
  top: 25%;
  left: 25%;
  max-width: 56px;
  animation: blink 1s infinite;
}

.task-3 {
  position: absolute;
  z-index: 10000;
  top: -20%;
  right: 0;
  max-width: 56px;
  animation: blink 1s infinite;
}

.task-1-button_timer {
  position: absolute;
  left: 14%;
  top: 20%;
}
.task-1-button_timer svg {
  max-width: 52px;
  height: 52px;
}

.task-2 {
  position: absolute;
  z-index: 10000;
  top: 25%;
  right: 25%;
  max-width: 56px;
  animation: blink 1s infinite;
}

.task-2-button_timer {
  position: absolute;
  right: 33%;
  top: 32%;
}

.task-2 {
  position: absolute;
  z-index: 10000;
  top: 25%;
  right: 25%;
  max-width: 56px;
  animation: blink 1s infinite;
}

.task-3-button_timer {
  position: absolute;
  right: 15%;
  top: 20%;
}

.task-2-button_timer svg {
  max-width: 52px;
  height: 52px;
}

.task-3-button_timer svg {
  max-width: 52px;
  height: 52px;
}

.task-1-city-timer {
  position: absolute;
  left: -30px;
  top: 60px;
  background-color: #ffffff59;
  padding: 10px;
  border-radius: 15px;
  p {
    background: linear-gradient(
      90deg,
      #fa00ff 0%,
      #04ddfb 99.99%,
      #00ffe0 100%
    );

    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }
}

.task-2-city-timer {
  position: absolute;
  right: 0px;
  top: 60px;
  background-color: #ffffff59;
  padding: 10px;
  border-radius: 15px;
  p {
    background: linear-gradient(
      90deg,
      #fa00ff 0%,
      #04ddfb 99.99%,
      #00ffe0 100%
    );

    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }
}

.task-3-city-timer {
  position: absolute;
  background-color: #ffffff59;
  padding: 10px;
  border-radius: 15px;
  right: -60px;
  top: 60px;
  p {
    background: linear-gradient(
      90deg,
      #fa00ff 0%,
      #04ddfb 99.99%,
      #00ffe0 100%
    );

    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }
}

.locked-task-button {
  position: absolute;
  right: 10%;
  top: 15%;
}
.locked-task-button img {
  max-width: 60px;
  height: auto;
}
.locked-task-button_second {
  right: 20%;
  top: 25%;
}

.finger {
  max-width: 80px;
  height: auto;
  position: absolute;
  z-index: 8;
  bottom: 0px;
  left: 50%;
  animation: tap 3s;
  opacity: 0;
}

.boss {
  max-width: 180px;
  left: 0;
  position: absolute;
  z-index: 10000;
  top: -60px;
}

.nopink-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.nopink-title {
  text-align: center;
  padding-top: 50px;
}

.locked-wrapper-seven {
  right: 20px;
  top: 85px;
}

.locked-ice-wrapper-seven {
  right: 25%;
  top: 20%;
}

.locked-wrapper-fourth {
  right: 86px;
  top: 140px;
}

.opened-task-seven {
  position: absolute;
  right: 20px;
  top: 85px;
  animation: blink 1s infinite;
}

.opened-task-fourth {
  position: absolute;
  right: 86px;
  top: 140px;
  animation: blink 1s infinite;
}

.map-completed-layout {
  height: 100%;
  width: 100%;
  position: relative;
}

.map-return {
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  display: flex;
  margin-top: 100px;
  flex-direction: column;
}

.return-text {
  margin-right: 8px;
}

.return-animal {
  position: relative;
  left: 0%;
  max-width: 100%;
  bottom: 0;
  width: fit-content;
}

.timer_bg {
  background-color: transparent !important;
}

.background-icon {
  position: absolute;
  max-width: 100%;
}

.tiger {
  max-width: 180px;
  position: absolute;
  top: 18%;
  z-index: 10000000;
  left: 0;
}

.close-icon {
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  right: 0;
  z-index: 10000;
  top: -20px;
}
.close-icon img {
  height: auto;
  max-width: 70px;
}

.close_map {
  right: 10px;
  top: 50px;
  img {
    max-width: 40px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}

.title {
  top: 0;
  align-self: center;
  position: relative;
  margin-top: 40px;
  padding: 19px 66px;
  display: flex;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.45px;
  background-color: #6fb8ed;
  text-align: center;
  border-radius: 25px;
  color: black;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  margin-bottom: 26px;
  text-transform: uppercase;
}

.question {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: white;
  padding: 0px 36px;
}

.tab-container {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}

.tab {
  position: relative;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 15px;
  width: 100%;
  white-space: nowrap;
  flex-shrink: 1;
  color: black;
  background: white;
  display: flex;
  justify-content: center;
  overflow: unset;
}

.tab::before {
  content: "";
  position: absolute;
  top: -6px;
  bottom: -6px;
  left: -5px;
  right: -5px;
  border-radius: 15px;
  z-index: -1;
  background: linear-gradient(90deg, #fa00ff 0%, #04ddfb 99.99%, #00ffe0 100%);
}

.tab.active {
  background: greenyellow;
}

.finger_one {
  position: absolute;
  max-width: 60px;
  top: 50%;
  left: 40%;
  animation: tap 3s;
  opacity: 0;
}

.finger_two {
  position: absolute;
  max-width: 60px;
  top: 50%;
  left: 60%;
  animation: tap 3s;
  opacity: 0;
}

.reward {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: white;
  padding: 0px 46px;
}

.treasure {
  position: absolute;
  bottom: 15%;
  max-width: 140px;
  left: 0px;
}

.gems {
  position: absolute;
  bottom: 14%;
  max-width: 70px;
  left: 25%;
}

.reward-coin {
  max-width: 80px;
}

.rewards-data {
  display: flex;
  position: relative;
  z-index: 100;
  margin-top: 25px;
  gap: 10px;
}

.coins-data {
  display: flex;
  color: white;
  font-weight: 600;
  align-items: center;
  font-size: 24px;
}

.gems-data {
  display: flex;
  color: white;
  font-weight: 600;
  align-items: center;
  font-size: 24px;
}

.coin {
  max-width: 60px;
}

.gem {
  max-width: 60px;
}

.claim-button {
  position: absolute;
  bottom: 0;
  background: linear-gradient(90deg, #fa00ff 0%, #04ddfb 99.99%, #00ffe0 100%);
  border-radius: 15px;
  text-transform: uppercase;
  max-width: 300px;
  width: 100%;
  padding: 17px 0;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
  z-index: 1000;
}

.leave-button {
  position: absolute;
  bottom: 0;
  background-color: #ff9533;
  border-radius: 15px;
  text-transform: uppercase;
  max-width: 300px;
  width: 100%;
  padding: 17px 0;
  border: none;
  color: white;
  font-weight: 700;
  z-index: 1000;
  font-size: 24px;
  margin-bottom: 20px;
}

.leave-button_nopink {
  max-width: 95%;
  left: 13px;
}

/*# sourceMappingURL=FirstTaskCity.module.css.map */
.task-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 70vh;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(24, 102, 255, 0.65) 0%,
    rgba(6, 39, 102, 0.65) 100%
  );
  position: absolute;
  bottom: 0;
  z-index: 1000;
}

.walrus {
  max-width: 180px;
  position: absolute;
  top: 18%;
  z-index: 10000000;
  left: 0;
}

/*# sourceMappingURL=FirstTaskIce.module.css.map */

@keyframes tap {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  25% {
    transform: translateY(-20px) translateX(-20px);
    opacity: 1;
  }
  50% {
    transform: translateY(0px) translateX(0);
    opacity: 1;
  }
  75% {
    transform: translateY(-20px) translateX(-20px);
    opacity: 1;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 0;
  }
}
@keyframes blink {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.1;
  }
  0% {
    scale: 1;
  }
}
.question {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: white;
  padding: 0px 36px;
  word-wrap: break-word; 
  width: 90%; 
  overflow: hidden; 
}

.tab-container {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  flex-wrap: wrap; 
  justify-content: center; 
}

.tab {
  height: auto;
  position: relative;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 15px;
  flex-shrink: 1;
  flex-basis: calc(33%);
  white-space: normal;
  color: black;
  background: white;
  justify-content: center;
  overflow: unset;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
}

.tab::before {
  content: "";
  position: absolute;
  top: -6px;
  bottom: -6px;
  left: -5px;
  right: -5px;
  border-radius: 15px;
  z-index: -1;
  background: linear-gradient(90deg, #fa00ff 0%, #04ddfb 99.99%, #00ffe0 100%);
}

.tab.active {
  background: greenyellow;
}

@media screen and (max-height: 700px) {
  .boss {
    max-width: 130px;
    left: 0;
    position: absolute;
    z-index: 10000;
    top: -59px;
  }

  .title {
    top: 0;
    align-self: center;
    position: relative;
    margin-top: 20px;
    padding: 5px 48px;
    display: flex;
    font-size: 23px;
    margin-bottom: 15px;
  }

  .treasure {
    position: absolute;
    bottom: 10%;
    max-width: 100px;
    left: 0px;
}
  .gems {
    position: absolute;
    bottom: 9%;
    max-width: 50px;
    left: 15%;
  }

  .tab-container {
    display: flex;
    margin-top: 5px;
    gap: 15px;
    justify-content: space-around;
    padding: 10px;
    flex-direction: column;
  }
  .rewards-data {
    display: flex;
    position: relative;
    z-index: 100;
    margin-top: 25px;
    gap: 10px;
  }
  .reward-coin {
    max-width: 50px;
  }
  
  .gem {
    max-width: 40px;
  }

  .close-icon img {
    height: auto;
    max-width: 40px;
  }


}
.disabled_img{
    position: absolute;
    bottom: 0;
    width: 100vw;

}
.timer_disabled{
  background: linear-gradient(90deg, #fa00ff 0%, #04ddfb 99.99%, #00ffe0 100%);
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
}