.buycoin{
        position: absolute;
        width: 50%;
        top: -10%;
}
.icon_finger{
    font-size: 65px;
}
.make_profit {
    text-align: center;
    font-size: 25px;
    font-weight: 900;
}
.blue_text{
    color: #7487f5;
}
.flex_buy{
    display: flex;
    font-size: 25px;
    gap: 20px;
    align-items: center;
    width: 100%;
    justify-content: center;
    z-index: 1;

    img{
        width: 20%;
    }
}
.mt-10{
    margin-top: 30%;
    text-align: center;
}