.earnings-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #121212;
  width: 100%;
  max-width: 800px;
  color: white;
  margin-top: 10px;
  position: relative;
  border-bottom: 2px dashed;
  padding-bottom: 10px;

    .info-section {
      display: flex;
      align-items: center;
  
      .icon {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 10px;
      }
  
      p {
        font-size: 16px;
      }
    }
  
    .bonus-section {
        display: flex;
        align-items: center;
        background-color: #0d4462;
        border-radius: 10px;
        justify-content: space-between;
        flex-direction: column;
        width: 40%;
        padding: 5px;
  
      .percentage {
        color: #00ff00; 
        font-size: 24px;
        font-weight: bold;
        width: max-content;
      }
  
      .bonus-details {
        display: flex;
        align-items: center;
        flex-direction: column;
        .bonus-item {
          display: flex;
          align-items: center;
  
          img {
            width: 20px;
            height: 20px;
          }
  
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
  .flex-pump {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .modal_content_pump {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: hidden;
    box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
    flex-direction: column;
    background: linear-gradient(45deg, #00f2ff, #0026ff7d);
}
  
  .earnings-component {
    .instruction-modal, .question-modal {
      padding: 20px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
    }

  
    .instruction-modal {

      background-image: url("./123.png"); 
      background-size: cover;
      background-position: center;
      min-height: 30vh;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-size: 24px;
        color: #121212;
        font-weight: 700;
      }
  
      
    }
  
    .question-modal {
      .options {
        display: flex;
        flex-direction: column;
  
        .option-button {
          margin: 10px 0;
          padding: 10px;
          background-color: #007bff;
          color: white;
          border: none;
          cursor: pointer;
          border-radius: 5px;
        }
      }
    }
  }
  .modal_pupm_container{
    
    button {
      margin-top: 20px;
      padding: 10px 30px;
      color: white;
      cursor: pointer;
      border-radius: 15px;
      font-weight: 700;
      font-size: 22px;
      border: 1px solid black;
      background: linear-gradient(90deg, #00f2ff, rgba(0, 38, 255, 0.4901960784));
    }
}
.tab_container {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;


  .tab::before {
    content: "";
    position: absolute;
    top: -6px;
    bottom: -6px;
    left: -5px;
    right: -5px;
    border-radius: 15px;
    z-index: -1;
    background: linear-gradient(90deg, #fa00ff 0%, #04ddfb 99.99%, #00ffe0 100%);
}

}

.modal-info {
  position: relative;

 
  
  @keyframes fall {
    0% {
      transform: translateY(-100px); 
      opacity: 1;
    }
    100% {
      transform: translateY(100%); 
      opacity: 0;
    }
  }
  
  
}
.confetti-icon {
  width: 50px;
  pointer-events: none; 
  animation: fall 3s ease-in forwards;
  z-index: 100;
}

.tab {
  transition: background-color 0.3s, color 0.3s; 
  background-color: #ffffff; 
  
  &.correct {
    background-color: #4caf50;
    color: white;
  }

  &.incorrect {
    background-color: #f44336; 
    color: white;
  }
}
.locked_task{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #7a7a7abd;
  border-radius: 15px;
}
.button_leave {
  min-height: 60px;
  padding: 12px 50px;
  color: white;
  font-weight: normal;
  font-size: 1.3rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: #ff9533;
  width: 100%;
}
.bonus-timer {
  color: rgb(195 195 195);
  font-weight: 800;
}

.percentage_task {
  color: lime;
  width: 100%;
  text-align: center;
  border-bottom: 2px dashed #ccc;
  margin-bottom: 5px;
}