.opened-wrapper {
  position: absolute;
  display: flex;
  width: 56px;
  border-radius: 12px;
  height: 56px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: none;
}

.stars-list {
  display: flex;
  list-style: none;
  position: relative;
  left: -5px;
}

.opened-icon {
  min-width: 30px;
  min-height: 30px;
}

.star-icon {
  position: absolute;
  list-style: none;
}

.star-icon:nth-child(1) {
  left: -20px;
  top: -70px;
}

.star-icon:nth-child(2) {
  left: -6px;
  top: -74px;
}

.star-icon:nth-child(3) {
  left: 8px;
  top: -70px;
}
