
$border-width: 3px;
$top-margin:30vh;
$top_margin_observe:5vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);
.outer-container_settings{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top_margin_observe);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top_margin_observe;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_settings {
    width: 100%;
    height: calc(100% - 6px);
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
    margin-bottom: -6px;
}
.settings_text {
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    height: 100%;
    padding-top: 10px;
}

.settings-container {
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    width: 100%;
}

.settings-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.settings-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #2a2a2a;
    border-radius: 20px;
    justify-content: space-between;
    color: white;
    border: 1px;
}

.settings-icon {
    height: 30px;
}

.settings-text {
    display: flex;
    flex-direction: column;
}

.settings-title {
    font-size: 18px;
    font-weight: bold;
}

.settings-subtitle {
    font-size: 14px;
    color: #bbb;
    display: flex;
}

.settings-item:last-child {
    margin-bottom: 0;
}
.lenguague_con {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #2a2a2a;
    border-radius: 20px;
    margin-bottom: 10px;
    justify-content: space-between;
    color: white;
}
.language-switcher{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.selected_lenguague{
    color: #7d7d7d;
    font-size: 12px;
}
.gradient-border {
    --borderWidth: 1px;
    background: #1D1F20;
    position: relative;
    border-radius: 20px;
    box-shadow: 3px 3px 5px rgb(251 79 255 / 10%), -3px -3px 0 rgb(37 177 255 / 10%), 3px -3px 5px rgb(251 79 255 / 10%), -3px 3px 5px rgb(37 177 255 / 10%);
  }
  .gradient-border:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: 20px;
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
  
  
  @keyframes animatedgradient {
      0% {
          background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
  }
  .bonus {
    color: #B2AAAA;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 2%;
    width: 40%;

    img{
        height: 20px;
    }
}
.glow_chest{
    position: absolute;
    width: 20vh;
    height: 11vh;
    background: radial-gradient(circle at center, rgba(173, 0, 255, 0.6509803922), rgba(118, 58, 215, 0) 66%);
    z-index: 0;
    right: -50px;
}
.chest_img{
    height: 45px;
    z-index: 10;
}

.rewardCon{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dailyReword {
    .calender_icon {
        height: 120px;
        margin-bottom: 20px;
    }

    .dailyText {
        margin-top: 10px;
        font-size: 14px;
    }

    .dailyInfo {
        text-align: center;
        padding: 0 80px;
    }
 
    .rewords {
        margin-top: 26px;
        margin-bottom: 50px;
        width: 90%;
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 10px;


        .gemImg{
            width: 15px !important;
            height: 15px !important;
        }

        .reword {
            width: 18.5%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: center;
            margin: 0;
            padding: 6px 0;
            border-radius: 10px;
            background: #454045;
            opacity: 0.6;
            width: 100%;
            justify-content: space-between;

            h3 {
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
            }

            p {
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                display: flex;
                align-items: center;
            }

            img {
                height: 33px;
                margin: 0;
            }

            &.Available {
                opacity: 1;
                border: 2px solid #52C661;
            }

            &.Claimed {
                opacity: 1;
                background: linear-gradient(90deg, #FA00FF 0%, #04DDFB 100%, #00FFE0 100%);
            }
        }
    }

    button {
        background: linear-gradient(90deg, #FA00FF 0%, #04DDFB 100%, #00FFE0 100%);
        color: white;
        border-radius: 10px;
        width: 85%;
        border: none;
        font-weight: 600;
        font-size: 30px;
        padding: 10px 0;

        &.disabled {
            background-color: #626262;
        }
    }
}

.drop_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #00efff, #1133f2);
    border-radius: 15px;
    max-height: 72px;
}
.usdt_balance_set {
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
    background: #35846d;
    font-size: 20px;
    font-weight: 900;
    border: 1px solid rgba(255, 255, 255, 0.231372549);
}
.container_activate{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text_sttings{
    font-size: 20px;
    font-weight: 900;
}
.copy-button {
    display: flex;
    flex-direction: row;
    min-height: 72px;
    border-radius: 15px;
    width: 100%;
    background: #343537;
    border: none;
    color: white;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}
.btn_copy{
    background: #ffffff;
    color: #b51c7d;
    font-size: 20px;
    font-weight: 700;
    padding: 5px;
    border-radius: 15px;
}
.title_copy{
    text-align: left;
    font-size: 19px;
    font-weight: 600;
}