.niki-drop {
    color: white;
    border-radius: 8px;
    text-align: center;
    position: relative;
    width: 100%;
    background: linear-gradient(45deg, #137fb7, #720f71);
  
    .niki-drop-header {
      h1 {
        font-size: 25px;
        width: 50%;
        text-align: left;
        margin-left: 10px;
        margin-top: 5px;
      }
    }
  
    .niki-drop-header {
        display: flex;
        flex-direction: row;
        width: 100%;

        .class{
          height: 100% !important;
          width: auto !important; 
        }
        
        .character-image {
            width: 50%;
            height: auto;
            position: absolute;
            z-index: 0;
            bottom: 0;
            right: 0;
          }
    }
    
  
      .niki-drop-buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-around;
        margin-top: 15px;
        margin-bottom: 5px;
        
        .niki-button {
            border: none;
            padding: 5px 10px;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            background: linear-gradient(45deg, #00acf97d, #44307d96);
            border-radius: 20px;
            color: #FDE400;
            z-index: 1;
        }
      }
  
    
  }
  .countdown-item_exchange{
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    color: white;
    font-family: Arial, sans-serif;
    width: 23%;
}

.countdown_exchange {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 30px;
}
.flex_exchange{
    display: flex;
    flex-direction: column;
    gap: 20px; 
}
.coming_soon_block{
    font-size: 30px;
    font-weight: 700;
}

