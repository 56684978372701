$border-width: 3px;
$top-margin: 3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg, #FB4FFF, #25B1FF
);

.outer_container_instruction {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 92vh;
    box-sizing: border-box;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
    margin-bottom: -20px;
    position: absolute;
    bottom: 0;

    &::before {
        content: '';
        position: absolute;
        top: -$border-width;
        left: $border-width;
        right: $border-width;
        bottom: $border-width;
        background: $gradient;
        z-index: -1;
        border-radius: inherit;
    }
}

.container_glow_instruction {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 0 0 5vw 0;
    margin: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #111111;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 3;
    gap: 2%;
}

.talesTelegramia {
    font-family: "Kaushan Script";
    -webkit-text-stroke: 0.5px white;
    font-size: 10vw;
    color: #0088CC;
    margin-top: 35px;
    z-index: 10;
    top: 0;
}

.der {
    position: absolute;
    top: -15%;
    z-index: 10;
    width: 55vw;
}

.flex_instruction {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 90%;
    font-size: 15px;
    color: #CACACA;
    gap: 15px;
    justify-content: center;

    img{
        width: 60px;
    }
}
.image_instruction {
    width: 75vw;
    margin-bottom: 0%;
}
.shadow {
    width: 50%;
    height: 0px;
    position: absolute;
    top: 50%;
    z-index: -1;
    
   
} 
.pink{
    box-shadow: 20px 20px 115px 86px #D62DDC;
    transform: rotate(60deg);
    right: 0;
}
.blue{
    box-shadow: 20px 20px 115px 86px #2D7DDC;
    transform: rotate(111deg);
    left: 0;
}
.next_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    position: absolute;
    bottom: 5%;
    right: 10px;
    font-weight: 600;
    z-index: 2;
}

.button_next {
    background: linear-gradient(90deg, #FA00FF 0%, #10D3FB 100%);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px #D62DDC;
    min-width: 50px;
    min-height: 50px;
    z-index: 2;
    img {
        width: 15px;
        height: 20px;
    }
}
.black {
    box-shadow: 0px 0px 115px 160px #000000;
    transform: rotate(0deg);
    bottom: 0;
    z-index: 1;
    width: 100%;
    top: 100%;
}
.start_btn {
    background: linear-gradient(90deg, #FB4FFF 25%, #25B1FF 61%);
    width: 80%;
    text-align: center;
    font-size: 2.7rem;
    font-weight: 600;
    border-radius: 10px;
    box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
}
.countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 90%;
}
.countdown-item {
    background: linear-gradient(90deg, rgba(0, 194, 255, 0.67) 0%, rgba(255, 0, 230, 0.38) 100%);
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    color: white;
    font-family: Arial, sans-serif;
    width: 23%;
}

.countdown-time {
    font-size: 2rem;
    font-weight: bold;
}

.countdown-label {
    font-size: 1rem;
}
.img_listing {
    width: 100vw;
    max-height: 50vh;
}


.container_glow_listing {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 0 0 5vw 0;
    margin: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #25143E;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 3;
    gap: 5%;
    background-image: url("./1.png");
}
.outer_container_listing {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 92vh;
    box-sizing: border-box;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
    margin-bottom: -20px;
    position: absolute;
    bottom: 0;
  


    &::before {
        content: '';
        position: absolute;
        top: -$border-width;
        left: $border-width;
        right: $border-width;
        bottom: $border-width;
        background: $gradient;
        z-index: -1;
        border-radius: inherit;
    }
}