$border-width: 3px;
$top-margin:10vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer_container_pet_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  box-sizing: border-box;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
  position: fixed;
  bottom: -30px;
  z-index: 10;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_p40 {
    width: calc(100% - 2*$border-width); 
    height: calc(100% - 2*$border-width);
    padding: 5vw;
    margin: $border-width;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #111111; 
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    position: relative;
    z-index: 1; 
    
  }
  .header_pet {
    margin: 5vw;
    background: linear-gradient(90deg, rgba(0, 194, 255, 0.67) 0%, rgba(255, 0, 230, 0.38) 100%);
    border-radius: 10px;
    padding: 12px;
    text-decoration: none;
    color: white;
    min-height: 64px;
}


.coin {
  height: 100%;
  background: linear-gradient(90deg, #FFD600 25%, #13EC00 61%, #2B8303 );
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: width 0.1s ease;
}
.coin-wrapper {
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.coin-icon {
  width: 16px;
  height: 16px;
}

.coin-counter {
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.coin-bar {
  width: 65%;
  height: 15px;
  background: #d5d5d569;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.feed_btn{
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  background: linear-gradient(90deg, #FA00FF 0%, #10D3FB 100%);
  border-radius: 15px;
  padding: 12px;
  text-decoration: none;
  color: white;
  min-height: 64px;
  box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
  
   
}

.disabled_btn {
  background: rgb(108 108 108);
  color: white;
  box-shadow: none;
}

.pet_img_main {
  max-height: 30vh;
}



.outer-container_pet {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}
.container_glow_mainpet {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  padding: 2vh 5vw 0vw 5vw;
  margin: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #111111;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
}


.d-flex{
  display: flex;
}

.pet-status-header {
  display: flex;
  background: linear-gradient(90deg, hsla(194, 100%, 50%, 0.67), hsla(306, 100%, 50%, 0.38));
  border-right: 1px solid rgb(250, 0, 255);
  border-left: 1px solid rgba(51, 0, 255, 0.51);
  border-radius: 15px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  align-items: center;
  padding: 0.7rem;
  position: relative;
  width: 90vw;
  justify-content: space-evenly;


  .avatar-section {
    position: relative;
    .star-icon {
      position: absolute;
      top: -10px;
      left: 40px;
      font-size: 2rem;
    }

    .pet-avatar {
      width: 60px;
      border: 1px solid;
      border-radius: 10px;
  }
  }

  .details-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 0.3rem;

    .pet-name {
      font-size: 1.5rem;
      margin-right: 2rem;
    }

    .pet-level-and-currency {
      display: flex;
      align-items: center;
      
      .coin-icon {
        width: 30px;
        height: auto;
    }

      .currency {
        font-size: 0.8rem;
        margin-right: 2rem;
      }

      .level {
        font-size: 0.8rem;
      }
    }

    
  }
  .additional-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    align-items: center;
    width: 35%;

    .currency-additional {
      font-size: 1.2rem;
      margin-right: 0.5rem;
      font-weight: 600;

      
    }

    svg{
      width: 20px;

    }
  }
}
/* MainPet.scss */

@keyframes flyingFood {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-50px) rotate(-20deg);
  }
  50% {
    transform: translateY(-100px) rotate(0deg);
  }
  75% {
    transform: translateY(-50px) rotate(20deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

.feed-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: flyingFood 2s ease infinite;
}
.food {
  width: 90vw;
  height: 50%;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}
.food-fly-icon{
  width: 35px;
}
.food-feed{
  width: 25px;
  margin-left: 10px;
}
.mainpet_container{
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.gradient_text_mainpet {
    font-size: 1.5rem;
    font-weight: bold;
    background-image: linear-gradient(90deg, #b035b3, #00ffe0);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.disabled_fed_container{
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.outer_container_pet_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
  position: absolute;
  bottom: -30px;
  z-index: 10;


&::before {
  content: '';
  position: absolute;
  top: -$border-width;
  left: $border-width;
  right: $border-width;
  bottom: $border-width;
  background: $gradient;
  z-index: -1;
  border-radius: inherit; 
}
}
.container_glow_mainpet_modal {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  padding: 2vh 5vw 0vw 5vw;
  margin: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #111111;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
}
.thanks_mainpet_img{
  max-height: 30vh;
  margin-top: 20%;
}
.green_mainpet {
  color: green;
  font-weight: 500;
  font-size: 0.9rem;
}
.coin_feed {
  width: 30px;
  height: auto;
}

.map_img{
  width: 7vw;
  max-height: 5vh;
}
.flex_timer{
    display: flex;
    align-items: center;
    gap: 2%;
}

.flex_pet{
  display: flex;
    align-items: center;
    justify-content: space-between;
}