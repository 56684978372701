.locked-wrapper {
  position: absolute;
  display: flex;
  width: 56px;
  background: linear-gradient(
    180deg,
    rgba(255, 138, 0, 0.75) 48.5%,
    rgba(255, 255, 255, 0.75) 100%
  );
  border-radius: 12px;
  height: 56px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: none;
}
.require-level {
  display: flex;
  color: black;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
}

.stars-list {
  display: flex;
  list-style: none;
  position: relative;
  left: -5px;
}

.locked-icon {
  min-width: 30px;
  min-height: 30px;
}

.star-locked-icon {
  position: absolute;
  list-style: none;
}

.star-locked-icon:nth-child(1) {
  left: -20px;
  top: -14px;
}

.star-locked-icon:nth-child(2) {
  left: -6px;
  top: -18px;
}

.star-locked-icon:nth-child(3) {
  left: 8px;
  top: -14px;
}
