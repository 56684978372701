.buy_coins_img{
    width: 100vw;
}

$border-width: 3px;
$top-margin:30vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer_container_play {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 85vh;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: -5px 0px 20px 0px rgb(249 0 255 / 16%), 5px -2px 20px 0px #2fb5ff4d;
    position: absolute;
    z-index: 1000;
    border-radius: 30px;


  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: -$border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_buy {
    width: 100%;
    height: calc(100% - 7px);
    padding: 3vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    position: relative;
    z-index: 1;
    justify-content: space-around;
    border-radius: 30px;
}
.mobile-prompt-container {
    .outer_container_play {
        .container_glow_play {
            position: relative;
            .coin-slider {
                -webkit-appearance: none;
                width: 70%;
                height: 15px;
                border-radius: 5px;
                background: linear-gradient(to right, #FB4FFF, #25B1FF);
                outline: none;

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 30px; 
                    height: 30px; 
                    border-radius: 50%;
                    background: url('https://nikicoin-bucket.s3.eu-north-1.amazonaws.com/images/nikicoin.png') no-repeat center center;
                    background-size: cover;
                    cursor: pointer;
                }

                &::-moz-range-thumb {
                    width: 30px; 
                    height: 30px;
                    border-radius: 50%;
                    background: url('https://nikicoin-bucket.s3.eu-north-1.amazonaws.com/images/nikicoin.png') no-repeat center center;
                    background-size: cover;
                    cursor: pointer;
                }
            }
        }
    }
}
.buycoins_coins_container{
    width: 85%;
    background: linear-gradient(to right, rgb(250 0 255 / 50%), rgba(0, 255, 224, 0.15), rgba(4, 221, 251, 0.59));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 5px;
    border-radius: 10px;
    border-left: 1px solid rgba(250, 0, 255, 1);
    border-right: 1px solid rgba(0, 255, 224, 1);
    gap: 10px;

    img{
        width: 30px;
    }
}
.buycoins_button{
    background: linear-gradient(to right, rgba(251, 79, 255, 1),rgba(37, 177, 255, 1));
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 5px;
    border-radius: 10px;
    border-left: 1px solid rgb(250, 0, 255);
    border-right: 1px solid rgb(0, 255, 224);
    gap: 10px;
    box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
}
.buycoins_glow {
    position: absolute;
    width: 90vw;
    height: 41vh;
    background: radial-gradient(rgba(173, 0, 255, 0.6), rgba(255, 255, 255, 0) 66%);
    z-index: -1;
    perspective: 1000px;
    top: 0;
    opacity: 0.6;
}
.column_buy {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 20px;
}
.offer {
    z-index: 2;
    position: fixed;
    top: 11vh;
    left: 0;
    width: 20%;
}
.try{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}