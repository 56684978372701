$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer-container_frieds {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top-margin;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_friends {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
}

.background_block {
    display: flex;
    align-items: center;
    background: #282B30;
    border-radius: 10px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(250, 0, 255, 0.53);
    border-left: 1px solid hsla(252, 100%, 50%, 0.37);
    flex-direction: column;
    margin-top: 10px;
    justify-content: center;
}

.button_invite {
    width: calc(100% + 32px);
    min-height: 60px;
    padding: 12px 8px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: -16px;
    font-size: 1.1rem;
    animation: pulse 2s infinite;
}

.row {
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly;
    width: 100%;
}

.ml-10{
    margin-left:10px
}

.friends_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}

.frieds_task {
    align-items: center;
    background: #282b30;
    border-left: 1px solid #3300ff5e;
    border-radius: 10px;
    border-right: 1px solid #fa00ff87;
    box-shadow: 0 4px 8px #0003;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 0;



    .name{
        font-size: 15px;
        margin: 0;
        font-weight: 300;
        margin-bottom: 5px;
    }
    .flex-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .infoWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .coins{
        font-weight: 600;
    }
    .img_friends {
        display: flex;
        width: 46px;
        margin-right: 10px;
        background: linear-gradient(90deg, rgba(251, 79, 255, 0.5) 0%, rgba(37, 177, 255, 0.5) 100%);       
         padding: 5px;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        font-size: 25px;
    }
    .league{
        margin-top: 5px;
        font-size: 10px;
    }
}
.tab_block_friends {
    align-items: center;
    border-radius: 10px;
    padding: 16px;
    width: 100%;
    gap:16px
}

.flex_center{
    display: flex;
    align-items: center;
}

@media screen and (max-width:390px) 
{
    .container_glow_friends {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .frieds_task {
        align-items: center;
        background: #282b30;
        border-left: 1px solid #3300ff5e;
        border-radius: 10px;
        border-right: 1px solid #fa00ff87;
        box-shadow: 0 4px 8px #0003;
        color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 10px 0;
    
        .name{
            font-size: 13px;
            margin: 0;
            font-weight: 300;
            margin-bottom: 5px;
        }
        .flex-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .infoWrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        .coins{
            font-weight: 600;
        }
        .img_friends {
            display: flex;
            width: 40px;
            margin-right: 5px;
            background: linear-gradient(90deg, rgba(251, 79, 255, 0.5) 0%, rgba(37, 177, 255, 0.5) 100%);           
             padding: 5px;
            border-radius: 10px;
            align-items: center;
            font-size: 25px;
            margin-left: 7px;
            justify-content: center;
        }
        .league{
            margin-top: 5px;
            font-size: 10px;
        }
    }
}
@keyframes pulse {
    0%, 100% {
      transform: scale(1);   
    }
    50% {
      transform: scale(1.02);  
    }
  }
  
  .invite_btn_container {
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: white;
    flex-direction: column;
    margin-top: 10px;
    justify-content: center;
    gap: 30px;
}
.button_create {
    width: calc(100% + 32px);
    min-height: 60px;
    padding: 12px 8px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: -16px;
    font-size: 1.1rem;

}

.glow_create_team {
    position: absolute;
    width: 420px;
    height: 420px;
    background: radial-gradient(circle at center, #763AD7, rgba(118, 58, 215, 0) 66%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    overflow: visible;
    z-index: -1;
}
.img_create_team{
    max-width: 35vw;
}
.container_glow_coming {
    width: 100%;
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #1F1F1F, #343434);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: center;
    align-items: center;
}
.button_create_create {
    width: 100%;
    min-height: 48px;
    padding: 12px 8px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: -16px;
    font-size: 1.1rem;
}
.subtext_create {
    font-size: 6vw;
    font-weight: 300;
}
.text_create {
    font-size: 6vw;
    font-weight: 300;
}
.text_container_create{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.container_glow_create {
    width: 100%;
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #1F1F1F, #343434);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: space-evenly;
    align-items: center;
}
.friend_img {
    height: 3rem;
    border-radius: 10px;
}

.friedns_row {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    font-weight: 500;
    font-size: 1rem;
    align-items: center;
}
.friedns_column {
    display: flex;
    flex-direction: column;
    width: 80%;
}
.completed{
    background: linear-gradient(90deg, hsl(194deg 100% 73.12% / 67%), hsl(306deg 100% 71.92%)) !important;
}
.gradient_block{
    background: linear-gradient(90deg, rgba(0, 194, 255, 0.67) 0%, rgba(255, 0, 230, 0.38) 100%);
}
.coin_friends{
    box-shadow: 0px 0px 20px 3px rgba(255, 255, 76, 0.4);
    border-radius: 20px;
    display: block;
    width: 32px;
    max-width: 110px;
    height: auto;
}
.star{
    display: block;
    width: 32px;
    max-width: 110px;
    height: auto;
    box-shadow: 0px 0px 20px 3px rgba(232, 123, 221, 0.4);
    border-radius: 33px;
    background: rgb(232 123 221 / 24%);
    
}