$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer-container_home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
  margin-bottom: -20px;



&::before {
  content: '';
  position: absolute;
  top: -$border-width;
  left: $border-width;
  right: $border-width;
  bottom: $border-width;
  background: $gradient;
  z-index: -1;
  border-radius: inherit; 
}
}

.container_glow_home {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  padding: 0 0 5vw 0;
  margin: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #111111;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
}

.container_p40 {
  width: calc(100% - 2*$border-width); 
  height: calc(100% - 2*$border-width);
  padding: 5vw;
  margin: $border-width;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #111111; 
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  position: relative;
  z-index: 1; 
  
}

.header_pet {
  margin: 5vw;
  background: linear-gradient(90deg, rgba(0, 194, 255, 0.67) 0%, rgba(255, 0, 230, 0.38) 100%);
  border-radius: 10px;
  padding: 12px;
  text-decoration: none;
  color: white;
  min-height: 64px;
}
.row_home {
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  gap: 15px;
  align-items: center;
  margin-bottom: 10px;
}
.tg_btn {
  border-radius: 15px;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.glow_effect_container {
    width: 100vw;
    height: 90%;
    display: flex;
    flex-direction: column;
    background: #111111;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}
.stats-coin {
    width: 60px !important;
    height: auto;
}
.coin-count {
    font-size: 2.2rem;
    font-weight: 700;
    margin-left: -7px;
}
.energy {
  height: 100%;
  background: linear-gradient(90deg, #FB4FFF 25%, #25B1FF 61%);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: width 0.1s ease;
}
.energy-wrapper {
  width: 90%;
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 3vh;
}

.energy-icon {
  width: 25px;
  height: 25px;
}

.energy-counter {
  margin-right: 4px;
  font-weight: 700;
  font-size: 16px;
}

.energy-bar {
  width: 100%;
  height: 15px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.boost_btn_img {
  width: 40px;
}
.boost_btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 5px;
  border-radius: 10px;
  background: linear-gradient(203deg, #fa00ffab 2%, #00FFE0 139%);
  color: white;
  text-decoration: none;
}
.league_text {
  font-size: 1rem;
  color: white;
  text-decoration: none;
  font-weight: 300;
}
.league_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  z-index: 1000;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  z-index: 1;
}

.gameplay-coin img {
  width: 65vw;
  height: auto;
  transition: transform 0.1s ease-out;
  perspective: 1000px;
  z-index: 1;
  position: relative;
}
.header{
  flex-direction: row;
}
.settings {
  width: 23%;
  height: 100%;
  background: linear-gradient(270deg, rgba(0, 194, 255, 0.67) 0%, rgba(255, 0, 230, 0.38) 100%);
  border-radius: 10px;
  padding: 6px;
  text-decoration: none;
  color: white;
  min-height: 64px;
}
.setting_logo{
  border-radius: 10px;
  height: 40px;
  object-fit: cover;
  width: 40px;
}
.setting_name{
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
.img_clicker {
  transition: transform 100ms ease-in-out;
}

.img_clicker.scale {
  transform: scale(0.99);
}
@media screen and (max-height: 650px) {
  .gameplay-coin img {
    width: 50vw;
  }
  .stat_text {
    font-size: 0.7rem;
  }
  .energy-bar{
    height: 10px;
  }
}
.amimation_boost_img {
  position: absolute !important;
  width: 90vw !important;
  height: auto !important;
  transition: transform 0.1s ease-out !important;
  perspective: 1000px !important;
  z-index: 0 !important;
  left: -20% !important;
  top: -20% !important;
  animation: rotate360 2s linear infinite;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stats_gem{
  width: 35px;
  height: auto;
  margin-right: -10px;
}

.gem_back{
  background: #6e1c75;
  border-radius: 15px;
  max-height: 30px;
}

.gem_count {
  font-size: 1.7rem;
  font-weight: 700;
  margin: 0 10px;
}


@keyframes pulse_home {
  0%, 100% {
      transform: scale(1);
  }
  50% {
    transform: scale(1.07);  
  }
}
.text-style {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #B3B3B3;
  letter-spacing: 0px;
  text-transform: none;
  text-shadow: 0px 0px 0px transparent;
  stroke: #FFFFFF;
  stroke-width: 1px;
}

.text-style {
  -webkit-text-stroke: 1px #FFFFFF;
  color: #B3B3B3; 
}

.text-style-bold {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 25px;
  color: #FFE300;
  letter-spacing: 0px;
  text-transform: none;
}

.text-style-bold {
  -webkit-text-stroke: 1px #1E3A65; 
}
.stats-container {
  display: flex;
  gap: 20px; 
}

.stats {
  display: flex;
  align-items: center;
  border-radius: 20px;
  max-height: 30px;
}

.gem-back {
  background-color: #8a2be2;
  color: white;
}

.usd-back {
  background-color: #2b443d;
  color: white;
}

.gem-count, .usd-count {
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
}

.stats img {
  width: 35px;
}

.stats-gem {
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.8));
}

.stats-usd {
  filter: drop-shadow(0px 0px 5px rgba(0, 255, 0, 0.8));
}


.warning_icon{
  padding: 10px;
  background: #2b443d;
  height: 40px;
  width: 40px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin-right: 5px;

}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;

  .popup-content {
    background: linear-gradient(to bottom, #00c9ff, #0078ff);
    border-radius: 10px;
    padding: 20px 40px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    color: white;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }

    .popup-icon {
      margin: 15px 0;

      .icon-check {
        color: #ffffff;
        background: #1c9e78;
        border-radius: 50%;
        padding: 10px;
      }
    }

    .popup-button {
      background: linear-gradient(to bottom, #99ff66, #66cc33);
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      font-size: 1rem;
      font-weight: bold;
      color: white;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
        background: linear-gradient(to bottom, #66cc33, #339900);
      }
    }
  }
}
