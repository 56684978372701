$border-width: 3px;
$top-margin:35vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer_container_thanks {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    // position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    position: fixed;
    bottom: 0;
    z-index: 1000;
  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_thanks {
    width: 100%;
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #1F1F1F, #343434);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
    margin-bottom: -6px;
}
.glow_thanks {
    position: absolute;
    width: 30vh;
    height: 30vh;
    background: radial-gradient(circle at center, #ad00ffa6, rgba(118, 58, 215, 0) 66%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 25%;
    overflow: visible;
    z-index: -1;
}
.img_thanks {
    width: 25vh;
}
.coins_thanks{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 700;
}
.coin_img_thanks {
    width: 60px;
    height: 60px;
}
.text_thanks{
    font-size: 1.5rem;
    margin: 30px 0;
}
.button_thanks {
    width: 90%;
    min-height: 60px;
    padding: 12px 8px;
    color: white;
    font-weight: normal;
    font-size: 1.3rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: linear-gradient(90deg, #FB4FFF 0%, #25B1FF 100%);
}
/* thanks.scss */
@keyframes flyCoin {
    0% {
      transform: translate(0, 0) rotateY(0deg);
      opacity: 1;
    }
    100% {
      transform: translate(var(--translate-x), var(--translate-y)) rotateY(360deg);
      opacity: 0;
    }
  }
  
  .flying-coin {
    position: absolute;
    width: 50px;
    height: 50px;
    background: url('./dollar_coin.png') no-repeat center center;
    background-size: contain;
    animation: flyCoin 1s ease-out;
    pointer-events: none;
    z-index: 10;
  }
  
  .gold-background {
    background-color: gold !important;
  }
  
  