$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer-container_games {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top-margin;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_games {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    padding: 3vw;
    margin: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
}

.game_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 8px;
    width: 100%;
    background: linear-gradient(90deg, rgba(112, 0, 255, 0.29) 0%, hsla(306, 100%, 50%, 0.06) 100%);
    border-right: 1px solid rgba(250, 0, 255, 0.53);
    border-left: 1px solid hsla(252, 100%, 50%, 0.37);
    padding: 14px;
    margin-bottom: 24px;
    border-radius: 25px;
    position: relative;
}

.button_play_games {
    width: 100%;
    min-height: 48px;
    padding: 12px 8px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 20px 0px;
}
.tab_content_games {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 480px;
  margin: 0 auto;
  padding: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.green{
  color: green;
  font-weight: 500;
}
.nikiboom-game-info > img {
  width: 70px;
    height: 70px;
    border-radius: 10%;
    object-fit: cover;
}
.new{
  position: absolute;
    top: 0;
    height: 50px !important;
    left: 0;
    width: auto !important;
}