.pointer {
    position: absolute;
    top: calc(50%);
    z-index: 2;
    display: flex;
    align-items: center;
    background: linear-gradient(270deg, rgba(0, 194, 255, 0.67) 0%, rgba(255, 0, 230, 0.38) 100%);
    border-radius: 15px;
    padding: 5px;

    .img1{
        width: 40px;
        height: 40px;
    }
     .img2 {
        width: 10px;
        height: 20px;
    }
    .img3 {
        width: 30px;
        height: 30px;
        margin: 5px;
    }
    
}
.right{
    right: 5px;
}
.left{
    left: 5px;
    transform: rotate(180deg);

}