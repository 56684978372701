$border-width: 3px;
$top-margin:3vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer-container_tasks {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top-margin;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: $border-width;
    right: $border-width;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_tasks {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  padding: 3vw;
  margin: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
}
.tasks-container{    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
    width: 100%;
}
.img_friends {
  margin-right: 10px;
  padding-top: 5px;
  border-radius: 10px;
  height: 82px;
  padding-left: 5px;
  width: 82px;
}
.tasks_con {
  display: flex;
  align-items: center;
  border-radius: 10px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(250, 0, 255, 0.53);
  border-left: 1px solid hsla(252, 100%, 50%, 0.37);

    .name{font-size: 15px;
        margin: 0;
        font-weight: 300;
        margin-bottom: 5px;}
}
.modal_content_tasks {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
  box-shadow: 3px 3px 20px rgba(251, 79, 255, 0.3), -3px -3px 20px rgba(37, 177, 255, 0.3), 3px -3px 20px rgba(251, 79, 255, 0.3), -3px 3px 20px rgba(37, 177, 255, 0.3);
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 5vw;
}
.button-open-task {
    width: 100%;
    min-height: 48px;
    padding: 10px 8px;
    margin-top: 60px;
    background-color: #FF9533;
    color: white;
    font-weight: normal;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.penalty{
    color: #FFA800;
    margin-top: 10px;
    text-align: center;
}
.task__description{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}
.tab_title {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.coin_icon {
  width: 40px;
  height: 40px;
}
.coin_icon_task{
  width: 25px;
  height: 25px;
}
.coins_reward{
  display: flex;
  align-items: center;
}
.green {
  color: green;
  font-weight: 500;
  font-size: 1.1rem;
}
@keyframes pulse {
  0%, 100% {
    transform: scale(1);   
  }
  50% {
    transform: scale(1.02);  
  }
}
.check_task {
  width: 100%;
  min-height: 48px;
  padding: 12px 8px;
  background-color: #FF9533;
  color: white;
  font-weight: normal;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: -16px;
  font-size: 1.1rem;
  animation: pulse 2s infinite; 
  margin-top: 5px;
}
.navButton_task {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  justify-content: center;
  width: 33%;
  padding: 5px 0;
  cursor: pointer;
  transition: background 0.3s ease;
  gap: 5px;
  height: 100%;
}

.doble_promotion {
  grid-column: span 2; 
}

.promotionTab {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  cursor: pointer;
  justify-content: flex-end;
  padding-bottom: 10px;
  height: 100%;


  .promotion_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    z-index: 1;
    border-radius: 15px;
  }

  .promotion_content {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
  }

  .row_promotion {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: 800;
    align-items: center;
    font-size: 17px;
  }

  .promotion_subtext {
    text-align: center;
    font-size: 11px;
    margin: 5px 0;
  }

  .promotion_title {
    background: linear-gradient(90deg, #fa00ffcf 0%, #04ddfba3 99.99%, #00ffe0d9 100%);
    padding: 4px;
    border-radius: 5px;
    font-weight: 700;
    text-align: center;
  }

  .promotion_img {
    width: 45%;
    border-radius: 10px;
    margin-top: 5px;
    padding: 10px 0 0 0;
    max-width: 80px;
  }

  .coin_img {
    width: 25px;
  }
}
.coming_soon{
  pointer-events: none;

}

.blocked_tasks {
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #fb4fff63 0%, #25b1ff80 100%) !important;
    flex-direction: column;
  font-weight: 600;
  gap: 5%;
  font-size: 1.5rem;
}

.invite_task{
  font-size: 1.2rem;
  color: white;
  background: #FF9533;
  border: none;
  padding: 10px;
  border-radius: 10px;
}
.additionalFriends {
  font-size: 16px;
  color: black;
  position: absolute;
  top: 40%;
  width: 90%;
  font-weight: 700;
  text-align: center;
}
.locked{
  font-size: 20px;
  color: #FFA800;
  width: 100%;
  text-align: center;

}
.divider_tasks{
  grid-column: span 2;
  height: 5px;
  background: #D9D9D9;
  border: none;
  border-radius: 5px;
}
.img_blocked{
  width: calc(100% + 10vw);
  height: 50%;
}
.MemeTaksCard {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  background: #282B30;
}
.petIcon{
  width: 100%;
}
.memeName {
  width: 100%;
  text-align: center;
  font-size: 15px;
}
.lockedCard{
  background: linear-gradient(90deg, #FB4FFF 0%, #25B1FF 100%) !important;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  opacity: 0.5;
  z-index: 0;
}
.infoWrapperMemeTask{
  z-index: 10;
}
.task_meme_flex{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.flex_info{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 10%;
}
.income_taksmeme{
  font-size: 13px;
  color: #3EA400;
  font-weight: 800;
}
.modal-container-tasks {
  width: 102%;
  height: 85%;
  background: linear-gradient(to bottom, #1F1F1F, #343434);
  border-radius: 30px 30px 0 0;
  position: absolute;
  bottom: -5px;
}

.scroll{
  overflow-y: scroll;
  width: 100%;
}
.modal-content_tasks{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.price_taskmeme{
  display: flex;
  align-items: center;
  flex-direction: row;
}
.max_height{
  max-height: 42vh;
}
.dex{
  display: flex !important;
  height: auto  !important;
  background: #72706d  !important;
  width: fit-content  !important;
  border-radius: 5px  !important;
  top: 10px  !important;
  position: absolute  !important;
  right: 10px  !important;
}
.countdown_listing_promotion {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, rgb(255 200 0) 0%, rgba(255, 166, 0, 0.3) 0%, rgb(172 154 34) 51%, rgb(214 68 5 / 53%) 100%);
  padding-right: 10px;
  z-index: 10;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.countdown_label_promotion {
  font-size: 0.5rem;
}
.countdown_time_promotion {
  font-size: 1rem;
  font-weight: bold;
}