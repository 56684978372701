$border-width: 3px;
$top-margin:30vh;
$top_margin_observe:10vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);
.outer-container_panamera{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top_margin_observe);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    margin-top: $top_margin_observe;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_panamera {
    width: 100%;
    height: calc(100% - 6px);
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #111111;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
    justify-content: flex-start;
    margin-bottom: -6px;
    overflow-y: scroll;
    overflow-x: hidden;

}
.container_glow_panamera_foot {
  width: 100%;
  height: calc(100% - 6px);
  padding: 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #111111;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;

  justify-content: space-around;
  margin-bottom: -6px;
  overflow-y: scroll;
  overflow-x: hidden;

  background-image: url("./gif.gif");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
}
.outer-container_panamera_home{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - $top_margin_observe);
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  overflow: hidden;
  box-shadow:
  3px 3px 20px rgba(251, 79, 255, 0.3), 
  -3px -3px 20px rgba(37, 177, 255, 0.3), 
  3px -3px 20px rgba(251, 79, 255, 0.3), 
  -3px 3px 20px rgba(37, 177, 255, 0.3); 
  margin-top: $top_margin_observe;


&::before {
  content: '';
  position: absolute;
  top: -$border-width;
  left: 0;
  right: 0;
  bottom: $border-width;
  background: $gradient;
  z-index: -1;
  border-radius: inherit; 
}
}

.container_glow_panamera_home {
  width: 100%;
  height: calc(100% - 6px);
  padding: 4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #111111;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
  margin-bottom: -6px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: url("./gif.gif");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 

}
.des_panamera{
    width: 35%;
}
.btn_panamera {
    width: 27%;
    display: flex;
    justify-content: flex-end;
}
.info_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    margin-bottom: 20px;
}
.image_container {
    padding: 10px;
    width: 60px;
    height: 60px;
    background: #575757;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img{
        width: 100%;
    }
    &::after{
        content: "";
        position: absolute;
        top: 100%;
        bottom: 0;
        width: 4px;
        background-color: #575757;
        height: 30px;
    }
}
$gradient-start: #FB4FFF;
$gradient-end: #25B1FF;
$button-radius: 30px;

.gradient-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #75640d;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 20px 6px rgb(133 116 5);
  position: relative;
  width: 90%;
  padding-right: 20px;

  .button-text {
    margin-right: 15px; 
    font-size: 15px;
  }

  .button-icon {
    width: 20px;
    height: 20px;
  }
  .icon_sun{
    width: 60px;
    }
}

.custom-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-family: "Arial, sans-serif";
  font-size: 16px;
  color: white;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
  width: 96%;
  margin-top: 30px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 20px 6px rgb(20, 122, 34);
  background: linear-gradient(132deg, #61FF00, #215800);

    .button-label {
      flex-grow: 1;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    }
  
    .button-count {
      display: flex;
      align-items: center;
      background-color: white;
      color: #32cd32;
      border-radius: 12px;
      padding: 5px 10px;
      font-weight: bold;
  
      .button-icon {
        height: 25px;
        margin-right: 5px;
      }
    }
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popup-container {
    background: linear-gradient(90deg, #06a3ae, #002fff);
    border-radius: 20px;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    color: white;
    box-shadow: 0px 0px 15px rgba(0, 255, 0, 0.5);
    text-align: center;
}
  
  h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    position: relative;
  }
  .task_icon {
    flex: 0 0 auto;
    width: 50px;
    height: 50px;
    font-size: 24px;
    border-radius: 50%;
    background: #4caff2;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

     img {
      width: 66%;
      aspect-ratio: 1 / 1;
    }
    &::after{
      content: "";
      position: absolute;
      top: 100%;
      bottom: 0;
      width: 4px;
      background-color: #4caff2;
      height: 30px;
  }
  }
  
  .task-text {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  
  .task-text span {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .task-status img {
    width: 20px;
    height: 20px;
  }
  .task-status {
    width: 32px;
    height: 32px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ok-button {
    padding: 10px 20px;
    background: #32cd32;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(50, 205, 50, 0.8);
    transition: all 0.3s ease;
    z-index: 10;
    position: relative;
}
.button-count{
    display: flex;
    align-items: center;
    background-color: white;
    color: #32cd32;
    border-radius: 12px;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 20px;
    img{
      height: 35px;
      margin-right: 5px;
    }
  }

  .usdt_balance {
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
    width: 40%;
    margin-top: 30px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 20px 10px rgb(49, 144, 116);
    background: #35846d;
    font-size: 32px;
    font-weight: 900;
    border: 1px solid rgba(255, 255, 255, 0.231372549);
}

.custom-button-later {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
  width: 96%;
  margin-bottom: 10px;
  background: #ff9533;
  min-height: 55px;
  text-align: center;
  font-weight: bolder;
}
.pending{
  background: #f99001;
  color: white;
}
.done{
  background: #368506;
  color: white;
}
.title_panamera{
  font-size: 20px;
  font-weight: 800;
}
.activated{
  background: #a5eb04;
  box-shadow: 0px 0px 20px 6px rgb(33 207 46);
}