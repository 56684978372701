$border-width: 3px;
$top-margin:30vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer_container_NotEnoughMoney{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    position: fixed;   
    bottom: 0;
    z-index: 1000;
  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_NotEnoughMoney {
  width: 100%;
  height: calc(100% - 6px);
  padding: 3vw;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #1F1F1F, #343434);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
  justify-content: space-evenly;
}
.glow_coming_NotEnoughMoney {
    position: absolute;
    width: 420px;
    height: 420px;
    background: radial-gradient(circle at center, #90408D, rgba(118, 58, 215, 0) 66%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    overflow: visible;
    z-index: -1;
}
.img_coming_NotEnoughMoney {
    width: 100vw;
    bottom: 0;
    left: 6px;
}
.text_coming_NotEnoughMoney{
    color: #FFA800;
    font-size: 10vw;
    font-weight: 700;
    top: 15%;
}

.buttom_NotEnoughMoney{
  width: 90%;
  min-height: 60px;
  padding: 12px 8px;
  color: white;
  font-weight: normal;
  font-size: 1.3rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: linear-gradient(90deg, #FB4FFF 0%, #25B1FF 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}