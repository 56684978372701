$border-width: 3px;
$top-margin:39vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);
.league_back{
    height: $top-margin;
    width: 100vw;
}
.league_container {
    background: #723ACE;
    overflow: hidden;
    overflow-y: hidden;
    height: 100vh;
    z-index: 0;
    position: relative;
}
.outer-container_league {
    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc(100vh - $top-margin);
    box-sizing: border-box;
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
    box-shadow:
    3px 3px 20px rgba(251, 79, 255, 0.3), 
    -3px -3px 20px rgba(37, 177, 255, 0.3), 
    3px -3px 20px rgba(251, 79, 255, 0.3), 
    -3px 3px 20px rgba(37, 177, 255, 0.3); 
    // margin-top: $top-margin;

  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: $border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_league {
  width: calc(100%);
  height: calc(100% - 6px);
  padding: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #111111;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  z-index: 1;
  justify-content: flex-start;
  margin-bottom: -6px;
}


.header_league {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
    align-items: center;
    font-size: 10rem;
    height: 100%;
    justify-content: space-between;
    padding: 0 40px;
    background-position: center center;
    background-repeat: no-repeat;
}
.header_league_background {
    position: absolute;
    width: 130%;
    height: 70vh;
    background: radial-gradient(circle at center, #FFFFFF, rgb(255 255 255 / 0%) 66%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 0%;
    overflow: visible;
    z-index: -1;
}
.league_name {
    font-size: 1.7rem;
    font-weight: 300;
    margin-bottom: 5px;
}
.league_additional{
    font-size: 1rem;
}
.info_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.img_coming_league {
    width: 80vw;
    position: absolute;
    bottom: 0;
}
.progress_bar_league {
    width: 100%;
    height: 15px;
    background: rgba(213, 213, 213, 0.4117647059);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-top: 10px;
}
.progress_league {
    height: 100%;
    background: linear-gradient(90deg, #FB4FFF 25%, #25B1FF 61%);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: width 0.1s ease;
}
.arrow_left {
    transform: rotate(180deg);
    left: 10px;
}
.arrows_container{
    display: flex;
    gap: 10px;
    align-items: center;
}
.arrow {
    top: calc(50% - 30px );
    width: 30px;
    position: absolute;
    right: 10px;
}
.logo_league {
    max-width: 37vw;
    margin-top: 5vh;
}
.coins_league{
    font-size: 1rem;
    font-weight: 600;
}
.coins_containers {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    bottom: 0;
    position: absolute;
}
.range_selector {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}
.range{
    width: 40%;
    text-align: center;
    padding-bottom: 10px;
    font-size: 1.1rem;


    
}
.selected_league{
    border-bottom: 1px solid white;

}
.league_info_item{
    
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
   

    img{
        width: 40px;
        border-radius: 10px;
    }

    span{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        
        .img{
            width: 25px;
            height: 25px;
        }
    }

}
.league_info {
    width: 100%;
    overflow-y: scroll;
}