$border-width: 3px;
$top-margin:30vh;
$border-radius: 30px;
$border-radius-input: 10px;
$gradient: linear-gradient(
  90deg,#FB4FFF,#25B1FF
           
);

.outer_container_play {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 85vh;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: -5px 0px 20px 0px rgb(249 0 255 / 16%), 5px -2px 20px 0px #2fb5ff4d;
    position: absolute;
    z-index: 1000;
    border-radius: 30px;


  
  &::before {
    content: '';
    position: absolute;
    top: -$border-width;
    left: 0;
    right: 0;
    bottom: -$border-width;
    background: $gradient;
    z-index: -1;
    border-radius: inherit; 
  }
}

.container_glow_play {
  width: 100%;
  height: calc(100% - 7px);
  padding: 3vw;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #111111;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  gap: 5%;
  justify-content: flex-start !important;
}

.oops_container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 800;

  img{
    max-width: 30%;
  }
}
.img_computer{
  max-width: 100%;
  position: fixed;
  bottom: 10px;
  z-index: 1000000;
}
.text_main{
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}
.gradient_oops {
  background: linear-gradient(90deg, #FB4FFF, #25B1FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
}
.btn_reload {
  display: flex;
  background: linear-gradient(90deg, #FB4FFF, #25B1FF);
  padding: 10px 20px;
  color: white;
  font-size: 1.4rem;
  font-weight: 800;
  border: none;
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  img{
    max-height: 35px;
  }

}